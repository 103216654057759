import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
    <g transform="translate(-8.000000, -2.000000)">
      <path
        d="M 30.00,64.25
          C 36.00,36.00 35.50,36.00 38.25,36.00
            41.00,36.00 43.88,35.25 44.25,38.25
            45.25,42.00 46.75,44.50 48.00,50.25
            50.50,55.75 49.25,59.25 53.00,47.50
            56.75,35.75 54.75,36.00 59.62,36.00
            64.50,36.12 63.38,35.38 65.38,43.62
            67.38,51.88 67.38,52.88 68.38,59.62
            69.62,65.62 69.38,64.00 65.75,64.25
            62.00,64.00 62.50,65.00 61.50,57.25
            60.50,49.50 60.62,49.50 60.25,48.00
            59.25,45.50 58.88,43.50 57.38,50.25
            55.88,57.00 54.88,59.88 53.50,64.12
            49.12,63.88 52.88,64.12 49.12,64.12
            45.38,64.12 46.88,65.12 44.38,56.38
            42.25,47.88 41.75,41.50 40.00,47.50
            39.25,53.88 39.62,53.75 38.38,61.62
            37.50,64.75 39.25,64.25 34.75,64.25M 113.75,45.00"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default IconLogo;
