import React from 'react';

const IconStackOverflow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 31.665 31.665">
    <title>StackOverflow</title>
    <path
      d="M 20.53125 0.46875 C 19.988281 0.542969 19.613281 1.046875 19.6875 1.59375 L 20.84375 9.53125 C 20.921875 10.074219 21.425781 10.453125 21.96875 10.375 C 22.515625 10.296875 22.890625 9.765625 22.8125 9.21875 L 21.6875 1.3125 C 21.609375 0.769531 21.078125 0.390625 20.53125 0.46875 Z M 14.5 2.5625 C 14.371094 2.585938 14.242188 2.644531 14.125 2.71875 C 13.664063 3.019531 13.542969 3.628906 13.84375 4.09375 L 18.21875 10.8125 C 18.519531 11.273438 19.132813 11.394531 19.59375 11.09375 C 20.058594 10.792969 20.175781 10.183594 19.875 9.71875 L 15.5 3 C 15.273438 2.65625 14.882813 2.492188 14.5 2.5625 Z M 10.125 6.75 C 9.871094 6.796875 9.625 6.960938 9.46875 7.1875 C 9.15625 7.640625 9.265625 8.246094 9.71875 8.5625 L 16.3125 13.125 C 16.761719 13.4375 17.375 13.324219 17.6875 12.875 C 18.003906 12.421875 17.890625 11.78125 17.4375 11.46875 L 10.875 6.9375 C 10.648438 6.78125 10.378906 6.703125 10.125 6.75 Z M 7.71875 11.5 C 7.332031 11.542969 6.992188 11.789063 6.875 12.1875 C 6.71875 12.714844 7.035156 13.28125 7.5625 13.4375 L 15.21875 15.6875 C 15.746094 15.84375 16.3125 15.558594 16.46875 15.03125 C 16.625 14.503906 16.308594 13.9375 15.78125 13.78125 L 8.125 11.53125 C 7.992188 11.492188 7.847656 11.484375 7.71875 11.5 Z M 2 16 L 2 23 C 2 24.65625 3.34375 26 5 26 L 17 26 C 18.65625 26 20 24.65625 20 23 L 20 16 L 18 16 L 18 23 C 18 23.550781 17.550781 24 17 24 L 5 24 C 4.449219 24 4 23.550781 4 23 L 4 16 Z M 7.125 16.125 C 6.574219 16.070313 6.085938 16.484375 6.03125 17.03125 C 5.976563 17.582031 6.390625 18.070313 6.9375 18.125 L 14.90625 18.90625 C 15.457031 18.960938 15.945313 18.550781 16 18 C 16.054688 17.453125 15.640625 16.960938 15.09375 16.90625 Z M 7 20 C 6.449219 20 6 20.449219 6 21 C 6 21.550781 6.449219 22 7 22 L 15 22 C 15.550781 22 16 21.550781 16 21 C 16 20.449219 15.550781 20 15 20 Z"
    />
  </svg>
);

export default IconStackOverflow;